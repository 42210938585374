$beige-1: #FBF7F1;
$beige-2: #F3E9D8;

$gray-1: #F0F0F0;
$gray-2: #C2C2C2;

$black-1: #151E29;
$black-2: #0C0C0C;

$blue-1: #1A6AFF;

$color-1: #FBF7F1;
$color-2: #F3E9D8;
$color-3: #F0F0F0;
$color-4: #C2C2C2;
$color-5: #151E29;
$color-6: #0C0C0C;
$color-7: #1A6AFF;
$color-8: #DBD1C5;
$color-9: #E5CEC0;
$color-10: #9A9D92;
$color-11: #ADB0A4;
$color-12: #7B5B37;
$color-13: #F3E9D8;
$color-14: #CBC3B9;
