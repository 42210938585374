@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "./colors.scss";

body {
  margin: 0;
}

body,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.MainSite,
.MainSite-layout {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: end;

  &.underline {
    text-decoration: underline;
  }

  svg {
    height: 20px;
  }
}

.Typography-title {
  font-family: inherit;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: $color-5;
  text-transform: uppercase;
}

.Typography-body {
  font-family: inherit;
  font-weight: 400;
  font-size: 15px;
  color: $color-5;
}

.Layout-row {
  display: flex;
  flex-flow: row;
}

.Layout-column {
  display: flex;
  flex-flow: column;
}

.Layout-10 {
  flex-basis: 10%;
}

.Layout-20 {
  flex-basis: 20%;
}

.Layout-30 {
  flex-basis: 30%;
}

.Layout-40 {
  flex-basis: 40%;
}

.Layout-50 {
  flex-basis: 50%;
}

.Layout-60 {
  flex-basis: 60%;
}

.Layout-70 {
  flex-basis: 70%;
}

.Layout-80 {
  flex-basis: 80%;
}

.Layout-90 {
  flex-basis: 90%;
}

@media (max-width: 750px) {
  .Layout-row {
    flex-flow: column;
  }
}