.Exposition {
  display: flex;
  flex-flow: row;
  width: 100vw;
  height: 600px;
  justify-content: space-between;
  align-items: center;

  .Arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100px;

    button {
      background: none;
      border: none;
      height: 60px;
      width: 60px;
      border-radius: 60px;
      background-color: #fff;

      &:hover {
        cursor: pointer;
      }
    }

    svg {
      height: 25px;
      width: 25px;
    }

    &.mirror {
      transform: scaleX(-1);
    }
  }

  .Carousel {
    flex: 1 1;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    .swiper {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.hover {
          cursor: pointer;
        }
      }
    }
  }
}

.Exposition.Exposition-modal {
  height: 100%;

  .Carousel {
    max-width: calc(100vw - 200px);

    .swiper {
      img {
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 750px) {
  .Exposition {
    position: relative;

    .Arrow {
      position: absolute;
      z-index: 100;
      right: 10px;

      &.mirror {
        left: 10px;
        right: initial;
      }
    }
  }

  .Exposition.Exposition-modal {
    .Carousel {
      max-width: initial;

      .swiper {
        img {
          object-fit: contain;
        }
      }
    }
  }
}

.ReactModalPortal {
  z-index: 1010;
}

.Modal-content {
  background-color: #fff;
  height: 100vh;
  width: 100vw;

  .Modal-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    svg {
      height: 30px;
      width: 30px;
    }
  }

  .Modal-fullscreen {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    svg {
      height: 10px;
      width: 10px;

      &:nth-of-type(1) {
        transform: scaleX(-1) translateY(15px) translateX(4px);
      }

      &:nth-of-type(2) {
        transform: scaleY(-1);
      }
    }

    &.Modal-active {
      svg {
        &:nth-of-type(1) {
          transform: scaleY(-1);
        }

        &:nth-of-type(2) {
          transform: scaleX(-1) translateY(-15px) translateX(-4px);
        }
      }
    }
  }
}