@import "../colors.scss";

.ContactForm {
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid $gray-1;
  box-shadow: 5px 5px 5px $gray-1;
  margin-bottom: 30px;

  .ContactForm-row {
    display: flex;
    flex-flow: row;
    align-items: end;
    justify-content: start;
    gap: 20px;
    max-width: 100%;

    .ContactForm-field {
      flex: 1 1;
      display: flex;
      flex-flow: column;
      align-items: start;
      justify-content: start;

      legend {
        padding: 0;
        margin-bottom: 8px;
      }

      textarea,
      input {
        width: 100%;
        box-sizing: border-box;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid $gray-1;
        transition: border 0.2s ease-in-out;

        &:focus {
          outline: none;
          border-bottom: 1px solid $blue-1;
        }
      }

      textarea {
        resize: vertical;
        min-height: 90px;
      }

      ::placeholder {
        color: $gray-2;
      }

      input[type="submit"] {
        cursor: pointer;
        background-color: $blue-1;
        color: #fff;
        border: none;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #175fe6;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .ContactForm {
    .ContactForm-row {
      flex-flow: column;

      .ContactForm-field {
        width: 100%;
      }
    }
  }
}