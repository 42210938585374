@import "../colors.scss";

.IndexPage {
  .IndexPage-banner {
    display: flex;
    position: relative;
    max-width: 100vw;
    height: 80vh;

    .banner {
      flex: 1;
      max-width: 100vw;
      object-fit: cover;
    }

    .banner-logo {
      position: absolute;
      right: 50%;
      bottom: 0;
      top: 26%;
      height: 74%;
      object-fit: contain;
    }
  }

  .IndexPage-navigation {
    height: 45px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: $color-8;

    nav {
      overflow-x: auto;
      scrollbar-width: none;
      width: fit-content;
      display: flex;
      flex-flow: row;
    }

    a,
    div.spacer {
      white-space: nowrap;
      display: flex;
      align-items: center;
      height: 100%;
      text-transform: uppercase;
      margin: 0 16px;
    }
  }

  .Section {
    padding: 50px 0;

    .Section-image {
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center
      }

      .floating-description {
        position: absolute;
        color: #fff;
        left: 50px;
        top: 50%;
      }
    }

    .Section-text {
      box-sizing: border-box;
      padding: 50px;
      gap: 20px;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;

      h2,
      p {
        margin: 0;
      }
    }

    .Section-social {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      gap: 30px;

      svg {
        fill: $color-8;
        height: 30px;
        width: 30px;
      }
    }

    .Section-casas,
    .Section-solucoes,
    .Section-dna {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }

    .Section-about {
      background-color: $color-1;
    }
  }
}