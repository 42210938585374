@import "../../colors.scss";

.GaleriaHeader {
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $color-8;

  nav {
    overflow-x: auto;
    scrollbar-width: none;
    display: flex;
    flex-flow: row;
    gap: 40px;
    padding: 0 40px;
  }

  .nav-link {
    white-space: nowrap;
    display: flex;
    flex-flow: column;
    align-items: start;

    span {
      white-space: nowrap;
      font-size: 22px;
      text-transform: uppercase;
    }
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 600;
    margin: 11px 0;
    font-size: 11px;

    svg {
      height: 10px;
      width: 10px;
    }
  }
}