@import "./colors.scss";

.MainSite-layout {
  display: flex;
  flex-flow: column;

  header {
    position: fixed;
    height: 90px;
    width: 100vw;
    z-index: 1001;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    background-color: $beige-1;

    .Header-logo-container {
      flex-basis: 35%;
      height: 100%;

      >* {
        margin-left: 90px;
      }

      a {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          height: calc(100% - 20px);
        }
      }

    }

    .Header-navigation {
      flex-basis: 65%;
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 16px;
        text-transform: uppercase;
      }
    }

    .burger-menu {
      display: none;
      flex-direction: column;
      cursor: pointer;
    }

    .burger-menu .bar {
      width: 25px;
      height: 3px;
      background-color: $black-1;
      margin: 4px 0;
    }
  }

  footer {
    display: flex;
    flex-flow: row;
    height: 265px;

    justify-content: space-evenly;
    align-items: end;

    .Footer-left {
      height: 100%;
      display: flex;
      align-items: end;

      .Footer-logo-container {
        height: 100%;
        display: flex;
        align-items: end;

        img {
          height: 212px;
        }
      }

      .Footer-contact {
        font-size: 12px;
        line-height: 14px;
        margin-left: 24px;
        margin-bottom: 24px;
      }
    }

    .Footer-center {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .Footer-social {
      gap: 30px;

      svg {
        fill: $color-8;
        height: 30px;
        width: 30px;
      }
    }

    .Footer-talk {
      font-size: 20px;

      a {
        display: flex;
        gap: 8px;
        align-items: end;

        svg {
          height: 20px;
        }
      }
    }

    .Footer-whatsapp {
      svg {
        fill: $color-8;
        width: 60px;
        height: 60px;
      }
    }
  }

  .MainSite-content {
    margin-top: 90px;
    flex: 1 1;
  }
}

.react-tiny-popover-container {
  z-index: 1000;

  .Header-popover {
    margin-top: 30px;
    margin-right: 2px;
    background-color: $color-1;

    a {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 16px 24px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 750px) {
  .MainSite-layout {
    header {
      .Header-navigation {
        display: none;
      }

      .burger-menu {
        flex-basis: 65%;
        justify-content: end;
        align-items: end;
        padding: 0 33px;
        display: flex;
      }
    }

    footer {
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
      padding-bottom: 50px;

      .Footer-spacer {
        display: none;
      }
    }
  }
}