@import "../colors.scss";

.SolucoesPage {
  .Typography-title {
    font-size: 28px;
  }

  .Image {
    height: 560px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center
    }
  }

  .form-disclaimer {
    font-size: 11px;
    font-weight: bold;
  }

  .Section.Solucoes-text {
    max-width: 50%;
    margin: auto;
    min-height: 560px;

    .Layout-row {
      gap: 40px;
    }
  }

  .LargeImage {
    position: relative;
    height: 1200px;
    max-width: 100vw;

    .background {
      max-width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      left: 25%;
      bottom: 0;
      height: 800px;
    }

    .overlay-body-title {
      display: none;
    }

    .overlay-text {
      position: absolute;
      left: calc(25% + 65px);
      top: 960px;
      font-size: 45px;
      text-transform: uppercase;
    }

    .overlay-body {
      position: absolute;
      left: 55%;
      width: 33%;
      bottom: 20px;
      font-size: 14px;
    }
  }

  .Section {
    .Section-image {
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center
      }

      .floating-description {
        position: absolute;
        color: #fff;
        left: 50px;
        top: 50%;
      }
    }

    .Section-text {
      box-sizing: border-box;
      padding: 50px;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;

      h2,
      p,
      .Section-social {
        margin: 0;
      }

      &.Section-limit {

        h2,
        p,
        .Section-social {
          max-width: 400px;
        }
      }
    }

    .Section-social {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      gap: 30px;

      svg {
        fill: $color-8;
        height: 30px;
        width: 30px;
      }
    }

    .Section-casas,
    .Section-solucoes,
    .Section-dna {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }

    .Section-about {
      background-color: $color-1;
    }
  }
}

@media (max-width: 750px) {
  .SolucoesPage {
    .LargeImage {
      .overlay {
        position: absolute;
        left: 20px;
        max-width: 30%;
        bottom: 0;
        height: 800px;
      }

      .overlay-text {
        display: none;
      }

      .overlay-body-title {
        display: block;
        font-size: 45px;
        text-transform: uppercase;
      }

      .overlay-body {
        position: absolute;
        left: initial;
        right: 20px;
        width: 70%;
        bottom: 20px;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .SolucoesPage {
    .LargeImage {
      .overlay {
        position: absolute;
        left: 20px;
        bottom: 0;
        height: 800px;
      }

      .overlay-text {
        position: absolute;
        left: calc(20px + 65px);
        top: 960px;
        font-size: 45px;
        text-transform: uppercase;
      }

      .overlay-body {
        position: absolute;
        left: initial;
        right: 20px;
        width: 50%;
        bottom: 20px;
        font-size: 14px;
      }
    }

    .Layout-row {
      flex-flow: column;
    }
  }
}