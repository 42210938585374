@import "../../colors.scss";

.Apartamentos {
  .Apartamentos-title {
    height: 45px;
    background-color: $color-1;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }

  .Apartamentos-description {
    max-width: 1600px;
    margin: auto;
    padding-bottom: 100px;

    .Typography-title {
      font-style: normal;
    }

    .Typography-body {
      font-size: 12px;
    }

    .Layout-row {
      gap: 60px;
    }
  }
}