@import "../colors.scss";

.ContatoPage {
  .Typography-title {
    font-size: 32px;
  }

  .inline {
    display: inline;
  }

  .Image {
    height: 560px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center
    }
  }

  .form-disclaimer {
    font-size: 11px;
    font-weight: bold;
  }

  .Section.Dna-text {
    max-width: 50%;
    margin: auto;
    min-height: 560px;
    
    .Layout-row {
      gap: 40px;
    }
  }

  .Section {
    .Section-image {
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center
      }

      .floating-description {
        position: absolute;
        color: #fff;
        left: 50px;
        top: 50%;
      }
    }

    .Section-text {
      box-sizing: border-box;
      padding: 50px;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      gap: 20px;

      h2,
      p,
      .Section-social {
        margin: 0 auto;
      }

      &.Section-limit {

        h2,
        p,
        .Section-social {
          max-width: 400px;
        }
      }
    }

    .Section-social {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      gap: 30px;

      svg {
        fill: $color-8;
        height: 30px;
        width: 30px;
      }
    }

    .Section-casas,
    .Section-solucoes,
    .Section-dna {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }

    .Section-about {
      background-color: $color-1;
    }
  }
}