@import "../../colors.scss";

.GaleriaPage {
  .Galeria-navigation {
    height: 170px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: $color-8;

    nav {
      overflow-x: auto;
      scrollbar-width: none;
      display: flex;
      flex-flow: row;
      gap: 40px;
      padding: 0 40px;
    }

    .nav-link {
      display: flex;
      flex-flow: column;
      align-items: start;
      white-space: nowrap;

      span {
        white-space: nowrap;
        font-size: 22px;
        text-transform: uppercase;
      }
    }

    a {
      display: flex;
      align-items: center;
      height: 100%;
      font-weight: 600;
      margin: 11px 0;
      font-size: 11px;

      svg {
        height: 10px;
        width: 10px;
      }
    }
  }

  .Galeria-separator {
    height: 240px;
    text-transform: uppercase;
    display: flex;
    font-size: 28px;
    justify-content: center;
    align-items: center;

    &.separator-2 {
      background-color: $color-1;
    }

    &.separator-3 {
      background-color: $color-11;
    }

    &.separator-4 {
      background-color: $color-14;
    }
  }

  .Galeria-showcase {
    .Section-text {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      min-width: 25vw;
      text-align: center;

      .Typography-title {
        font-size: 42px;
        text-transform: none;
        font-style: normal;
      }

      .Typography-body {
        box-sizing: border-box;
        padding: 40px;
      }
    }

    .Section-image {
      img {
        height: 100%;
        max-width: 100vw;
        object-fit: cover;
      }
    }

    &:last-of-type {
      margin-bottom: 40px;
    }
  }
}
